import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=e6ad0a68"
import script from "./Breadcrumbs.vue?vue&type=script&setup=true&lang=ts"
export * from "./Breadcrumbs.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbsRow: require('/app/components/Breadcrumbs/BreadcrumbsRow.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default})
