// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row_i7\\+7\\+{align-items:center;display:inline-flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row_i7+7+"
};
module.exports = ___CSS_LOADER_EXPORT___;
